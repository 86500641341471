<template>
  <!-- 站点、网关管理界面 -->
  <div class="create-screen">
    <div v-loading.fullscreen.lock="fullscreenLoading">
      <div class="table-layout">
        <el-row :gutter="20">
          <el-form
            ref="siteForm"
            :model="siteForm"
            label-width="100px"
            :inline="true"
            @submit.native.prevent
          >
            <el-row class="btnRow">
              <el-col :span="5" class="elrow">
                <el-form-item
                  label="唯一编码"
                  prop="searchParam"
                  style="padding-left: 10px"
                >
                  <el-input
                    v-model="siteForm.searchParam"
                    size="mini"
                    clearable
                    @clear="clearAll"
                    style="width: 160px"
                  ></el-input>
                </el-form-item>
              </el-col>
              <search-button
                
                @clearAll="clearAll"
                @exportEvent="exportEvent"
                @importEvent="importEvent"
                @searchEvent="searchSenser"
                @closepop="closepop"
              ></search-button>
            </el-row>
          </el-form>
        </el-row>
        <br />

        <el-row style="margin-bottom: 20px">
          <el-col :span="1.5" class="elrow">
            <el-button
              style="width: 80px"
              v-if="hasPermission('add')"
              type="text"
              icon="el-icon-plus"
              size="mini"
              class="editBtn"
              @click="addVisiableSensor"
              >新增</el-button
            >
          </el-col>
          <el-col :span="1.5" class="elrow">
            <el-button
              v-if="hasPermission('edit')"
              style="width: 80px"
              type="text"
              icon="el-icon-refresh"
              size="mini"
              class="editBtn"
              @click="editSensor"
              >修改</el-button
            >
          </el-col>
          <el-col :span="1.5" class="elrow">
            <el-button
              v-if="hasPermission('delete')"
              style="width: 80px"
              type="text"
              class="deleteBtn"
              icon="el-icon-delete"
              size="mini"
              @click="delSensor"
              >删除</el-button
            >
          </el-col>
          <el-col :span="1.5" class="elrow">
            <el-button
              v-if="hasPermission('bangding')"
              style="width: 80px"
              type="text"
              icon="el-icon-refresh"
              size="mini"
              class="editBtn"
              @click="bangdingSensor"
              >绑定变压器</el-button
            >
          </el-col>
        </el-row>
        <el-table
          :data="yList"
          :cell-style="{ 'text-align': 'center' }"
          :row-style="getRowClass"
          :header-row-style="getRowClass"
          :header-cell-style="getRowClass"
          @selection-change="handleSelectionChangeNode"
          border
          style="width: 100%;height: 100%;"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column min-width="4%" label="序号">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column min-width="8%" label="站点ID" v-if="false"
            ><template slot-scope="scope">{{
              scope.row.site_transformer_id
            }}</template></el-table-column
          >
          <el-table-column min-width="8%" label="站点名称"
            ><template slot-scope="scope">{{
              scope.row.site_name
            }}</template></el-table-column
          >
          <el-table-column min-width="8%" label="省级名称"
            ><template slot-scope="scope">{{
              scope.row.city_level
            }}</template></el-table-column
          >
          <el-table-column min-width="8%" label="地区名称"
            ><template slot-scope="scope">{{
              scope.row.county_level
            }}</template></el-table-column
          >
          <el-table-column min-width="8%" label="经度"
            ><template slot-scope="scope">{{
              scope.row.longitude
            }}</template></el-table-column
          >
          <el-table-column min-width="8%" label="纬度"
            ><template slot-scope="scope">{{
              scope.row.latitude
            }}</template></el-table-column
          >
          <!-- <el-table-column min-width="13%" label="设备数目"
            ><template slot-scope="scope">
              <el-button
                style="width: 120px"
                type="text"
                class="editBtn"
                size="small"
                >{{
                  scope.row.kgg.length ? scope.row.kgg.length : 0
                }}</el-button
              >
            </template></el-table-column
          > -->
        </el-table>
        <div class="pagination">
          <el-pagination
            align="center"
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-size="pageSize"
            layout="prev,pager,next,total,jumper"
            :total="tableData.length"
          >
          </el-pagination>
        </div>
      </div>
      <div>
        <el-dialog
          title=""
          :append-to-body="true"
          :visible.sync="centerDialogVisible"
          custom-class="custom-dialog"
          width="30%"
          center
        >
          <div class="item1">
            <br />
            <el-form
              label-position="left"
              label-width="150px"
              :model="siteForm"
            >
              <el-form-item label="类型:" :required="true" prop="entity">
              </el-form-item>
              <el-form-item label="上传xlsx文件:">
                <el-upload
                  ref="upload"
                  action="#"
                  :auto-upload="false"
                  :multiple="false"
                  :file-list="fileList"
                  :before-upload="beforeUpload"
                  class="upload-demo uploadImages"
                  :http-request="uploadHttpRequest"
                  :on-remove="handleRemove"
                  :on-change="handleChange"
                >
                  <el-button slot="trigger" class="btn-text" type="primary"
                    >选取文件</el-button
                  >
                </el-upload>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" class="btn-text" @click="submitUpload"
                  >上传</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </el-dialog>
      </div>
      <!--新增单条网关信息管理-->
      <div>
        <el-dialog
          title=""
          :append-to-body="true"
          :visible.sync="singleAddDialogVisible"
          custom-class="custom-dialog"
          width="30%"
          center
        >
          <div class="openDigl">
            <div class="item1">
              <br />
              <el-form
                label-position="left"
                label-width="100px"
                :model="siteForm"
              >
                <el-form-item label="站点id:" :required="true" v-if="false">
                  <el-input
                    class="btn-text"
                    v-model="siteForm.site_transformer_id"
                  ></el-input>
                </el-form-item>
                <el-form-item label="站点名称:" :required="true">
                  <el-input v-model="siteForm.site_name"></el-input>
                </el-form-item>
                <el-form-item
                  label="省级名称:"
                  :required="true"
                  v-if="addorEdittext == '修改'"
                >
                  <!-- <el-input v-model="siteForm.city_level"></el-input> -->

                  <el-select
                    v-model="siteForm.city_level"
                    placeholder="请选择所在省份"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="具体区域:"
                  :required="true"
                  v-if="addorEdittext == '修改'"
                >
                  <el-input v-model="siteForm.county_level"></el-input>
                </el-form-item>
                <el-form-item
                  label="经度:"
                  :required="true"
                  v-if="addorEdittext == '修改'"
                >
                  <el-input v-model="siteForm.longitude"></el-input>
                </el-form-item>
                <el-form-item
                  label="纬度:"
                  :required="true"
                  v-if="addorEdittext == '修改'"
                >
                  <el-input v-model="siteForm.latitude"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    type="primary"
                    class="btn-text"
                    @click="handleClick"
                    >{{ addorEdittext }}</el-button
                  >
                </el-form-item>
              </el-form>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <!-- 5、绑定区域-->
    <!--绑定弹窗需要加载用户所需能看到的菜单或者路由-->
    <div>
      <el-dialog
        title="绑定传感器"
        :append-to-body="true"
        :visible.sync="shouquanDialogVisiable"
        custom-class="custom-dialog"
        width="40%"
        center
      >
        <el-form>
          <el-form-item>
            <el-tree
              node-key="test_equipment_id"
              ref="tree"
              :data="menuList"
              :props="menuProps"
              :default-checked-keys="defaultKey"
              :default-expanded-keys="defaultKey"
              show-checkbox
            ></el-tree>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="btn-text" @click="shouquanEvent">{{
              addorEditshoquan
            }}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  getAllSites,
  addNewSite,
  editNewSite,
  delSite,
  getDevicesById,
  getAllDevices,
  addDeviceSite,
  searchByqInfo
} from "@/api/index";
import SearchButton from "./tool/button.vue";
export default {
  data() {
    return {
      fullscreenLoading: false,
      pageSize: 20,
      currentPage1: 1,
      selctionNodeRows: [],
      nodeids: [],
      centerDialogVisible: false,
      singleAddDialogVisible: false,
      shouquanDialogVisiable: false,
      addorEdittext: "新增",
      addorEditshoquan: "绑定",
      siteForm: {
        site_name: "",
        site_transformer_id: "",
        city_level: "",
        county_level: "",
        longitude: "",
        latitude: "",
        searchParam:""
      },
      options: [
        { label: "河北", value: "河北" },
        { label: "山西", value: "山西" },
        { label: "辽宁", value: "辽宁" },
        { label: "吉林", value: "吉林" },
        { label: "吉林", value: "吉林" },
        { label: "吉林", value: "吉林" },
        { label: "黑龙江", value: "黑龙江" },
        { label: "江苏", value: "江苏" },
        { label: "浙江", value: "浙江" },
        { label: "安徽", value: "安徽" },
        { label: "福建", value: "福建" },
        { label: "江西", value: "江西" },
        { label: "山东", value: "山东" },
        { label: "河南", value: "河南" },
        { label: "湖北", value: "湖北" },
        { label: "湖南", value: "湖南" },
        { label: "广东", value: "广东" },
        { label: "海南", value: "海南" },
        { label: "四川", value: "四川" },
        { label: "贵州", value: "贵州" },
        { label: "云南", value: "云南" },
        { label: "陕西", value: "陕西" },
        { label: "甘肃", value: "甘肃" },
        { label: "青海", value: "青海" },
        { label: "台湾", value: "台湾" },
        { label: "内蒙古", value: "内蒙古" },
        { label: "广西", value: "广西" },
        { label: "西藏", value: "西藏" },
        { label: "新疆", value: "新疆" },
        { label: "宁夏", value: "宁夏" },
      ],
      menuProps: {
        label: "equipment_name",
        children: "children",
      },
      defaultKey: [],
      arrBegin: [],
      fileList: [],
      tableData: [],
      originTableData: [],
      menuList: [],
      // 选中数组
      ids: [],
      selctionRows: [],
      yList: [],
      typeoptions: [
        { id: "1", name: "tev" },
        { id: "2", name: "ae" },
        { id: "3", name: "hfct" },
        { id: "4", name: "tem" },
      ],
      onlineOptions: [
        { label: "在线", value: "1" },
        { label: "离线", value: "2" },
        { label: "故障", value: "3" },
      ],
      uploadoptions: [
        { id: "1", name: "正常" },
        { id: "2", name: "异常" },
        { id: "3", name: "中断" },
      ],
      showAll: true,
    };
  },
  components: {
    SearchButton,
  },
  watch: {
    defaultKey: {
      handler(newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.tree.setCheckedKeys(newVal);
          });
        }
      },
      immediate: false,
      deep: true,
    },
  },
  computed: {
    tableHeight() {
      if (this.showAll) {
        return window.innerHeight - 140;
      } else {
        return window.innerHeight - 200;
      }
    },
  },
  mounted() {
    this.getSensorType();
  },
  filters: {
    timeFormat: (value) => {
      // return value ? value.substring(0,value.indexOf(".")).replace('T',' ') : ''
      return value ? value.replace("T", " ") : "";
    },
  },
  methods: {
    // 加载表格数据 查询站点信息
    getSensorType() {
      getAllSites()
        .then((res) => {
          if (res.code == 200) {
            this.tableData = res.data;
            //   this.tableData = sortBy(this.tableData, ["upload_time"]).reverse();
            this.originTableData = this.tableData;
            this.yList = this.tableData.slice(
              (this.currentPage1 - 1) * this.pageSize,
              this.currentPage1 * this.pageSize
            );
          } else {
            this.$message.error(res.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hasPermission(permissionName) {
      let elementUi = [];
      let arr = this.formatTree(this.$store.getters.permissions, "0");
      arr.forEach((v) => {
        if (v.attribute_name == "台账管理") {
          v.children.forEach((m) => {
            if (m.attribute_name == "站点管理") {
              m.children.forEach((j) => {
                elementUi.push(j.remarks);
              });
            }
          });
        }
      });
      return elementUi.includes(permissionName);
    },
    // 后端反的格式转化成数组嵌套
    formatTree(arr, attribute_id) {
      let newArr = [];
      arr.forEach((item) => {
        if (item.parent_id == attribute_id) {
          item.children = this.formatTree(arr, item.attribute_id);
          newArr.push({
            ...item,
          });
        }
      });
      return newArr;
    },
    // 单条节点管理
    handleSelectionChangeNode(selection) {
      this.selctionNodeRows = selection;
      console.log(selection,'selection');
      
      this.nodeids = selection.map((item) => item.id); // 需要根据数据情况调整id名称
    },
    // 文件上传之前的操作
    beforeUpload(file) {
      // 文件大小限制为20M
      const fileLimit = file.size / 1024 / 1024 < 2000;
      if (!fileLimit) {
        console.log("上传文件大小不超过2000M！");
      }
      // 返回判断条件,false停止上传
      // return fileType && fileLimit;
      return fileLimit;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    // 新增传感器弹窗信息
    addVisiableSensor() {
      this.addorEdittext = "新增";
      this.siteForm = {
        site_name: "",
        city_level: "",
        county_level: "",
        longitude: "",
        latitude: "",
      };
      this.singleAddDialogVisible = true;
    },
    // 自定义上传方法，param是默认参数，可以取得file文件信息，具体信息如下图
    uploadHttpRequest(param) {
      // FormData对象
      let formData = new FormData();
      formData.append("file", param.file);
      // formData.append("entity", this.nodeForm.entity);
      uploadFormData(formData)
        .then((res) => {
          var msg = "";
          if (res.code === 200) {
            this.$message.success("上传成功");
            // this.nodeForm.entity = "";
            this.fileList = [];
            this.centerDialogVisible = false;
            // this.getTableData();
          } else {
            msg = res.error;
            this.$message.error("上传失败！原因是" + msg);
            this.fileList = [];
          }
        })
        .catch((err) => {
          this.$refs.upload.clearFiles();
          this.fileList = [];
        });
    },
    // 上传文件
    submitUpload() {
      // 判断是否选择了文件，若没有，则后边支持导入
      if (this.fileList.length < 1) {
        this.$message.warning("请选择上传的文件！");
      } else {
        // 显示散点图谱
        this.centerDialogVisible = false;
        this.$nextTick(() => {
          this.$refs.upload.submit();
        });
      }
    },
    // 新增站点信息
    addSensor() {
      addNewSite(this.siteForm).then((res) => {
        if (res.code == 200) {
          this.$message.success("添加成功！");
          this.getSensorType();
        } else {
          this.$message.error(res.error);
        }
        this.singleAddDialogVisible = false;
      });
    },
    // 修改站点信息弹窗
    editSensor() {
      if (this.nodeids.length !== 1) {
        this.$message.warning("请选择一条数据进行修改操作！");
      } else {
        this.siteForm.site_transformer_id =
          this.selctionNodeRows[0].site_transformer_id;
        this.siteForm.site_name = this.selctionNodeRows[0].site_name;
        this.siteForm.city_level = this.selctionNodeRows[0].city_level;
        this.siteForm.county_level = this.selctionNodeRows[0].county_level;
        this.siteForm.longitude = this.selctionNodeRows[0].longitude;
        this.siteForm.latitude = this.selctionNodeRows[0].latitude;

        // city_level: this.siteForm.city_level,
        // county_level: this.siteForm.county_level,
        // longitude: this.siteForm.longitude,
        // latitude: this.siteForm.latitude
        this.singleAddDialogVisible = true;
        this.addorEdittext = "修改";
      }
    },
    // 批量删除或删除单个角色
    delSensor() {
      if (this.nodeids.length == 0) {
        this.$message.warning("请至少选择一条数据进行删除操作！");
      } else {
        delSite({
          site_transformer_id: this.selctionNodeRows[0].site_transformer_id,
        })
          .then((res) => {
            if (res.code == 200) {
              this.$message.success("删除成功");
              // 请求表格数据
              this.getSensorType();
            } else {
              this.$message.error(res.error);
            }
          })
          .catch(() => {});
      }
    },
    // 绑定设备
    bangdingSensor() {
      // 打开弹窗 绑定的弹窗加载设备列表，选中一个设备绑定即可
      // 加载所有权限列表
      getAllDevices().then((res) => {
        if (res.code == 200) {
          this.menuList = res.data;
          this.menuList = this.formatMM(this.menuList, "equipment_type_id", "equipment_type", "开关柜");
        }
      });
      if (this.nodeids.length !== 1) {
        this.$message.warning("请选择一条数据进行修改操作！");
      } else {
        this.defaultKey = [];
        // 判断一下，如果有过绑定的话，那么就改成修改绑定；如果没有绑定的话，还是绑定；通过查询绑定结果得到的结果不为空
        getDevicesById(this.selctionNodeRows[0].site_transformer_id)
          .then((res) => {
            if (res.code == 200) {
              if (res.data.length > 0) {
                res.data.forEach((v) => {
                  this.defaultKey.push(v.test_equipment_id);
                });
                this.shouquanDialogVisiable = true;
                this.addorEditshoquan = "修改绑定";
                // 如果有可能的话，这块需要把绑定赋值过去，以便用户修改
              } else {
                this.shouquanDialogVisiable = true;
                this.addorEditshoquan = "绑定";
                // 这里就是加载所有权限列表，以供用户在列表中单选
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    formatMM(array, key, typeNameKey, defaultTypeName = "默认类型") {
      return array.reduce((result, currentItem) => {
        // 查找是否有匹配的 bucket
        let bucket = result.find((item) => item[key] === currentItem[key]);

        // 如果没有找到匹配的 bucket，则创建一个新的 bucket
        if (!bucket) {
          // 尝试从 currentItem 中获取 typeName，如果不存在则使用默认值
          const typeName = currentItem[typeNameKey] || defaultTypeName;
          bucket = {
            ["equipment_name"]: typeName, // 设置自定义的类型名
            [key]: currentItem[key], // 设置分组键
            children: [], // 初始化子项数组
          };
          result.push(bucket); // 将新创建的 bucket 添加到结果数组中
        }

        // 将当前项添加到 bucket 的 children 数组中
        bucket.children.push(currentItem);

        // 返回更新后的结果数组
        console.log(result)
        return result;
      }, []); // 初始结果数组为空
    },
    // formatMM(array, key) {
    //   return array.reduce((result, currentItem) => {
    //     // 使用 key 作为分类的标识，找到对应的分类数组，如果不存在则创建
    //     const bucket = result.find(
    //       (item) => item[key] === currentItem[key]
    //     ) || { ...currentItem, children: [] };

    //     // 将当前项加入到对应分类的 children 数组中
    //     if (!bucket[key]) {
    //       bucket[key] = currentItem[key];
    //     }
    //     bucket.children.push(currentItem);

    //     // 如果 bucket 不在结果数组中，则添加进去
    //     if (!result.includes(bucket)) {
    //       result.push(bucket);
    //     }
    //     return result;
    //   }, []);
    // },
    // 绑定事件
    shouquanEvent() {
      // 绑定事件
      if (this.addorEditshoquan == "绑定") {
        this.addOneAuth();
      } else {
        this.editOneAuth();
      }
    },
    // 新增绑定
    addOneAuth() {
      let params = {
        site_transformer_id: this.selctionNodeRows[0].site_transformer_id,
        test_equipment_ids: [],
      };
      this.$refs.tree.getCheckedNodes().forEach((v) => {
        if (v.test_equipment_id) {
          params.test_equipment_ids.push(v.test_equipment_id);
        }
      });
      params.test_equipment_ids = Array.from(
        [...new Set(params.test_equipment_ids)]
      );
      addDeviceSite(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("绑定成功！");
        } else {
          this.$message.error("绑定失败！原因是" + res.error);
        }
        this.shouquanDialogVisiable = false;
        this.getSensorType();
      });
    },
    editOneAuth() {
      console.log(this.selctionNodeRows);
      let params = {
        site_transformer_id: this.selctionNodeRows[0].site_transformer_id,
        test_equipment_ids: [],
      };
      this.$refs.tree.getCheckedNodes().forEach((v) => {
        if (v.test_equipment_id) {
          params.test_equipment_ids.push(v.test_equipment_id);
        }
      });
      params.test_equipment_ids = Array.from(
        [...new Set(params.test_equipment_ids)]
      );
      addDeviceSite(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("绑定成功！");
        } else {
          this.$message.error("绑定失败！原因是" + res.error);
        }
        this.shouquanDialogVisiable = false;
        this.getSensorType();
      });
    },
    getRowClass({ row, column, rowIndex, columnIndex }) {
      return "background:#3f5c6d2c;text-align:center";
    },
    handleSizeChange1(val) {
      this.currentPage1 = 1;
      this.pageSize = val;
      this.yList = this.tableData.slice(
        (this.currentPage1 - 1) * this.pageSize,
        this.currentPage1 * this.pageSize
      );
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.yList = this.tableData.slice(
        (this.currentPage1 - 1) * this.pageSize,
        this.currentPage1 * this.pageSize
      );
    },
    handleClick() {
      if (this.addorEdittext == "新增") {
        this.addSensor();
      } else {
        this.relationEdit();
      }
    },
    // 修改变电站
    relationEdit() {
      // 使用链接put 修改
      let params = {
        site_transformer_id: this.siteForm.site_transformer_id,
        site_name: this.siteForm.site_name,
        city_level: this.siteForm.city_level,
        county_level: this.siteForm.county_level,
        longitude: this.siteForm.longitude,
        latitude: this.siteForm.latitude,
      };
      editNewSite(params).then((res) => {
        if (res.code == 200) {
          this.$message.success("修改成功！");
          // 关闭弹窗 加载列表
          this.singleAddDialogVisible = false;
          this.getSensorType();
        }
      });
    },
    editBtn(row) {
      console.log(row);
    },
    deleteBtn(row) {
      console.log(row);
    },
    closepop() {
      this.showAll = !this.showAll;
    },

    searchSenser() {

      searchByqInfo(this.siteForm.searchParam)
        .then((res) => {
          if (res.code == 200) {
            console.log(res,'结果');
            this.yList = res.data
          } else {
            this.$message.error(res.error);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      console.log("您进入了父组件，点击了查询之后可以得到：" + this.siteForm.searchParam);
    },
    // 重置
    clearAll() {
    this.getSensorType();

    },
    importEvent() {
      // 打开文件导入的弹窗
      this.centerDialogVisible = true;
    },
    exportEvent() {
      this.$message.success("导出按钮");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./table.scss";
.btnRow {
  display: block;
}

.unBtnRow {
  display: none;
}
::v-deep .custom-dialog .el-dialog__body {
  height: auto;
  overflow: hidden;
  background-color: #062b7cc2;
  border: 1px solid #409eff;
  color: aliceblue;
  overflow: auto;
}

.openDig {
  display: flex;
  background-color: #285ed4c2;
}
.custom-dialog {
  ::v-deep .el-card__body,
  .el-main {
    padding: 5px;
  }

  ::v-deep .el-image__inner {
    margin-top: 20%;
  }

  .dga_data {
    width: 100%;
  }
  ::v-deep .el-form-item__label {
    font-size: 18px;
    color: #041634;
    line-height: 40px;
  }

  ::v-deep .el-dialog__header {
    padding: 0px !important;
    background-color: #041634 !important;
  }

  ::v-deep .el-dialog__body {
    background-color: #041634;
  }

  ::v-deep .el-dialog__footer {
    background-color: #041634;
  }

  ::-webkit-scrollbar {
    width: 1px;
  }
  //滚动条滑块
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgb(3, 33, 92);
    transition: 0.3s ease-in-out;
  }
}
::v-deep .el-tree {
  position: relative;
  cursor: pointer;
  background: transparent;
  color: #fff;
}
::v-deep .el-tree-node__content {
  &:hover {
    background: #6f9ec9;
  }
}
::v-deep .el-tree-node.is-current > .el-tree-node__content {
  background-color: rgb(20, 107, 107) !important;
}
</style>

